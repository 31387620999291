import React from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'

import { useTheme } from '@/Hooks'
import { PagesEnum } from '@/Navigators/Application'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import useLoginDialog from '@/Hooks/useLoginDialog'

interface BannerProps {
  navigation: any
  getMeData: any
  isAssistantEnabled: boolean
}

const Banner = ({ navigation, getMeData, isAssistantEnabled }: BannerProps) => {
  const { Images, Layout, Gutters } = useTheme()
  const openLoginDialog = useLoginDialog()

  const handlePressAIChatRoom = () => {
    if (!isAssistantEnabled) {
      return
    }

    if (getMeData) {
      navigation.navigate(AuthPagesEnum.AIChatContainer)
    } else {
      openLoginDialog()
    }
  }

  return (
    <View style={[styles.container, Gutters.regularHPadding]}>
      <TouchableOpacity
        style={[styles.banner]}
        onPress={() =>
          navigation.navigate(PagesEnum.SpiritsCategoriesContainer)
        }
      >
        <Image
          source={Images.home_category_banner}
          defaultSource={Images.home_category_banner}
          resizeMode="cover"
          style={[Layout.fullWidth, styles.bannerImage]}
        />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.banner]} onPress={handlePressAIChatRoom}>
        <Image
          source={Images.home_ai_chat_banner}
          defaultSource={Images.home_ai_chat_banner}
          resizeMode="cover"
          style={[Layout.fullWidth, styles.bannerImage]}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    marginBottom: 32,
  },
  banner: {
    width: '48%',
  },
  bannerImage: {
    borderRadius: 8,
    height: 77,
  },
})

export default React.memo(Banner)
