import React, { useEffect, useMemo, useState } from 'react'
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'

import { FadeInView, LoadingComponent } from '@/Components'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { PagesEnum } from '@/Navigators/Application'

import useGetPairingsData from '../hooks/useGetPairingsData'

interface PairingContainerProps {
  navigation: any
}

const TITLE = '配餐'

const PairingContainer = ({ navigation }: PairingContainerProps) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()

  const { pairingsData, pairingDataIsLoading, filterMetaDataIsLoading } =
    useGetPairingsData()

  const [listData, setListData] = useState(pairingsData)
  const [searchText, setSearchText] = useState('')

  const styles = getStyle()

  const handleClickCard = (key: string) => {
    navigation.navigate(PagesEnum.FilterSearchListContainer, {
      pairings: key,
    })
  }

  useEffect(() => {
    if (pairingsData) {
      setListData(pairingsData)
    }
  }, [pairingsData])

  useEffect(() => {
    if (searchText.length > 0) {
      setListData(
        pairingsData.filter(
          pairing =>
            pairing.enName.toLowerCase().includes(searchText.toLowerCase()) ||
            pairing.zhName.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    }
    if (searchText.length === 0) {
      setListData(pairingsData)
    }
  }, [pairingsData, searchText])

  const renderLoading = useMemo(() => {
    if (filterMetaDataIsLoading || pairingDataIsLoading) {
      return (
        <View style={[Layout.fill, Layout.rowCenter, Gutters.largeTPadding]}>
          <LoadingComponent backgroundColor="transparent" />
        </View>
      )
    }
  }, [
    Layout.fill,
    Layout.rowCenter,
    Gutters,
    filterMetaDataIsLoading,
    pairingDataIsLoading,
  ])

  return (
    <FadeInView duration={500} style={Layout.fill}>
      <Image
        source={Images.search_landing_banner}
        style={[styles.articleBanner]}
        resizeMode="cover"
      />
      <View
        style={[Layout.fill, Gutters.regularHPadding, Gutters.smallTPadding]}
      >
        <View style={[styles.filterCardHeader]}>
          <Text
            style={[
              Fonts.weight700,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {`探索 - ${TITLE}`}
          </Text>
        </View>
        <View style={[styles.searchBar]}>
          <Image source={Images.search} style={[Layout.iconSize24]} />
          <TextInput
            style={[
              styles.searchInput,
              { color: Colors.fontText.light.primary2 },
            ]}
            value={searchText}
            onChangeText={setSearchText}
            placeholder={`搜尋${TITLE}`}
            placeholderTextColor={Colors.fontText.light.primary3}
          />
          {searchText.length > 0 && (
            <TouchableOpacity onPress={() => setSearchText('')}>
              <Image source={Images.close} style={[Layout.iconSize24]} />
            </TouchableOpacity>
          )}
        </View>
        <View>
          <View style={[styles.pairingCardContainer]}>
            {renderLoading}
            {listData.map(pairing => (
              <TouchableOpacity
                style={[styles.pairingCard]}
                onPress={() => handleClickCard(pairing.enName)}
              >
                <View>
                  <Text style={[Fonts.size20]}>{pairing.emoji}</Text>
                  <View style={[styles.pairingCardContent]}>
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        Gutters.miniBMargin,
                        { color: Colors.fontText.light.primary2 },
                      ]}
                    >
                      {pairing.zhName}
                    </Text>
                  </View>
                </View>
                <View
                  style={[Layout.row, Layout.alignItemsCenter, styles.count]}
                >
                  <Image
                    source={Images.search_landing_bottle_icon}
                    style={[Layout.iconSize14, Gutters.miniRMargin]}
                  />
                  <Text
                    style={[
                      Fonts.weight300,
                      Fonts.size12,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    {pairing.count}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    </FadeInView>
  )
}

const getStyle = () =>
  StyleSheet.create({
    filterCardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    articleBanner: {
      width: '100%',
      height: 180,
    },
    searchBar: {
      marginBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: Colors.fontText.light.primary3,
      borderRadius: 8,
    },
    searchInput: {
      flex: 1,
      marginLeft: 10,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    pairingCardContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 12,
    },
    pairingCard: {
      width: '48%',
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderWidth: 1,
      borderColor: Colors.background.top,
      borderRadius: 8,
    },
    pairingCardContent: {
      width: '100%',
      maxWidth: 115,
    },
    count: {
      justifyContent: 'flex-end',
    },
  })

export default PairingContainer
