import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'

import { Colors } from '@/Theme/Variables'
import { useTheme } from '@/Hooks'

interface BottomSubmitButtonProps {
  handleSubmit: () => void
  title: string
  disabled?: boolean
}

const BottomSubmitButton = ({
  handleSubmit,
  title,
  disabled = false,
}: BottomSubmitButtonProps) => {
  const { Fonts, Gutters } = useTheme()

  const styles = getStyle({
    disabled,
  })

  return (
    <View style={[Gutters.regularHPadding, styles.submitButtonContainer]}>
      <TouchableOpacity
        style={[styles.submitButton]}
        onPress={handleSubmit}
        disabled={disabled}
      >
        <Text
          style={[
            Fonts.weight500,
            Fonts.size16,
            { color: Colors.fontText.dark.primary2 },
          ]}
        >
          {title}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

BottomSubmitButton.defaultProps = {
  disabled: false,
}

const getStyle = ({ disabled }: { disabled: boolean }) =>
  StyleSheet.create({
    submitButton: {
      position: 'absolute',
      bottom: 16,
      width: '90%',
      height: 48,
      backgroundColor: disabled ? Colors.background.top : Colors.primary,
      borderRadius: 10,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
    },
    submitButtonContainer: {
      width: '100%',
      height: 80,
      backgroundColor: Colors.background.surface,
    },
  })
export default BottomSubmitButton
