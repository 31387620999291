import React from 'react'
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Markdown from 'react-native-markdown-display'

import { Colors } from '@/Theme/Variables'
import { useTheme } from '@/Hooks'

import CustomSpiritCard from './customSpiritCard'
import { AIChatRoomMessage, SpiritCard } from '../types'

interface CustomMessageProps {
  message?: AIChatRoomMessage
  navigation: any
  isTyping: boolean
}

export enum MessageType {
  AI_REFERENCES = 3,
  AI_MESSAGE = 2,
  USER_MESSAGE = 1,
}

const checkIsReference = (message: AIChatRoomMessage | undefined) => {
  return message?.user?.id === MessageType.AI_REFERENCES
}

const checkAIMessage = (message: AIChatRoomMessage | undefined) => {
  return message?.user?.id === MessageType.AI_MESSAGE
}

const CustomMessage = (props: CustomMessageProps) => {
  const { Fonts, Images, Layout, Gutters } = useTheme()

  const handleFindSimilar = (item: SpiritCard) => {
    const searchKeyword = `請推薦與 ${item.title} 相似風味的酒`
    props.navigation.setParams({ keyword: searchKeyword })
  }

  // 如果是推薦卡片
  if (checkIsReference(props.message)) {
    return (
      <View
        key={props.message?.id}
        style={{
          backgroundColor: Colors.background.default,
          width: '100%',
        }}
      >
        <ScrollView
          horizontal={true}
          contentContainerStyle={[styles.AIMessageSpiritCardContainer]}
        >
          {JSON.parse(props.message?.text || '')?.map((item: SpiritCard) => (
            <View style={styles.spiritCardContainer}>
              <CustomSpiritCard item={item} navigation={props.navigation} />
              <TouchableOpacity
                style={styles.findMessageContainer}
                onPress={() => handleFindSimilar(item)}
              >
                <Text
                  style={[
                    Fonts.size12,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  找相似
                </Text>
              </TouchableOpacity>
            </View>
          ))}
        </ScrollView>
      </View>
    )
  }

  // 渲染 AI 訊息
  if (checkAIMessage(props.message)) {
    return (
      <View key={props.message?.id} style={styles.AIMessageContainer}>
        {props.message?.text && (
          <View style={[Gutters.tinyBMargin]}>
            <Image
              style={[Layout.iconSize32]}
              source={Images.ai_chat_icon}
              resizeMode="contain"
            />
          </View>
        )}
        <View
          style={[
            styles.AIMessageMessageContainer,
            { display: !props.message?.text ? 'none' : 'flex' },
          ]}
        >
          <Markdown style={markdownStyle}>{props.message?.text}</Markdown>
        </View>
      </View>
    )
  }

  // 渲染使用者訊息
  return (
    <View key={props.message?.id} style={styles.userMessageContainer}>
      <Markdown style={markdownStyle}>{props.message?.text}</Markdown>
    </View>
  )
}

const styles = StyleSheet.create({
  AIMessageContainer: {
    paddingBottom: 16,
  },
  AIMessageMessageContainer: {
    width: '100%',
    marginRight: 'auto',
    backgroundColor: Colors.background.onSurface,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 16,
    borderRadius: 14,
    marginBottom: 10,
  },
  userMessageContainer: {
    width: 'auto',
    maxWidth: '80%',
    marginLeft: 'auto',
    backgroundColor: Colors.background.surface,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 14,
    marginBottom: 10,
  },
  AIMessageSpiritCardContainer: {
    alignContent: 'stretch',
    marginBottom: 10,
  },
  findMessageContainer: {
    width: 'auto',
    alignSelf: 'flex-start',
    maxWidth: '100%',
    backgroundColor: Colors.background.surface,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 14,
    marginBottom: 10,
  },
  spiritCardContainer: {
    flexDirection: 'column',
    gap: 8,
  },
})

const markdownStyle = {
  body: {
    color: Colors.white,
    lineHeight: 24,
  },
}

CustomMessage.defaultProps = {
  message: undefined,
}

export default CustomMessage
