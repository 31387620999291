import React, { useCallback, useState } from 'react'
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import StarRating from 'react-native-star-rating-widget'
import { useTranslation } from 'react-i18next'

import { Colors } from '@/Theme/Variables'
import { useFlavorData, useTheme } from '@/Hooks'
import { StarIcon14 } from '@/Components/SpiritCard'
import { capitalizeFirstLetter } from '@/Util/global'
import { getFlavorColor } from '@/Containers/data/FlavorData'

import { SpiritCard } from '../types'
import spiritsApi from '@/Services/modules/spirits'
import { PagesEnum } from '@/Navigators/Application'
import { useSelector } from 'react-redux'
import { RootState } from '@/Store'

interface CustomSpiritCardProps {
  item: SpiritCard
  navigation: any
}

const CustomSpiritCard = ({ item, navigation }: CustomSpiritCardProps) => {
  const { Fonts, Layout, Gutters, Images } = useTheme()
  const { t } = useTranslation()
  const { language } = useSelector((state: RootState) => state.theme)
  // 需要先取得風味資料放入 i18n 裡面
  const { isSuccess: getFlavorAPIIsSuccess } = useFlavorData()

  // 加入 wishlist
  const [addWishlistRequest] = spiritsApi.usePostAddWishlistMutation({})

  // 移除 wishlist
  const [removeWishlistRequest] = spiritsApi.usePostRemoveWishlistMutation({})

  const [collectState, setCollectState] = useState(item.collected)

  // 加入或移除 wishlist
  const handleAddWishlist = useCallback(
    (isCollected: boolean, spiritId: string) => {
      if (isCollected) {
        removeWishlistRequest({
          id: 'default',
          spiritId,
        })
        setCollectState(false)
      } else {
        addWishlistRequest({
          id: 'default',
          spiritId,
        })
        setCollectState(true)
      }
    },
    [addWishlistRequest, removeWishlistRequest],
  )

  const handlePress = () => {
    navigation.navigate(PagesEnum.ProductDetail, {
      lang: language,
      id: item.id,
    })
  }

  // 如果是整數，如 5 分則顯示 5.0 分，非整數則不調整
  const renderConvertRating = useCallback((rating: number) => {
    return rating.toString().includes('.') ? rating : rating.toFixed(1)
  }, [])

  const renderFlavors = useCallback(
    ({ item }: { item: string }) => {
      const color = getFlavorColor(item)
      return (
        <View style={[styles.flavorsItem]}>
          <View
            style={[
              styles.flavorDot,
              Gutters.miniRMargin,
              { backgroundColor: color },
            ]}
          />
          <Text
            style={[
              Fonts.size12,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {capitalizeFirstLetter(t(`flavor-wheel:${item}`))}
          </Text>
        </View>
      )
    },
    [Fonts.size12, Fonts.weight400, Gutters.miniRMargin, t],
  )

  return (
    <TouchableOpacity
      key={item.id}
      style={styles.spiritCardContainer}
      onPress={handlePress}
    >
      <View style={[Layout.row, Gutters.smallBMargin, { gap: 16 }]}>
        <View style={styles.spiritCardImageContainer}>
          <Image
            style={[Layout.fullWidth, Layout.fullHeight, { borderRadius: 8 }]}
            source={{ uri: item.imageUrl }}
          />
        </View>
        <View style={{ width: 155, gap: 8 }}>
          <Text
            style={[
              Fonts.size12,
              Fonts.weight400,
              { width: '100%', color: Colors.fontText.light.primary2 },
            ]}
            numberOfLines={2}
          >
            {item.brand}
          </Text>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { width: '100%', color: Colors.fontText.light.primary2 },
            ]}
            numberOfLines={2}
          >
            {item.title}
          </Text>
          <View>
            {item.ratings.average && item.ratings.count ? (
              <View style={[Layout.row, Layout.alignItemsCenter, { gap: 4 }]}>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  {renderConvertRating(item.ratings.average)}
                </Text>
                <Text
                  style={[Fonts.size12, Fonts.weight400, { color: '#707070' }]}
                >{`${item.ratings.count} 個評分`}</Text>
              </View>
            ) : (
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                暫無評分
              </Text>
            )}
          </View>
          <StarRating
            rating={item.ratings.average || 0}
            starSize={20}
            color="#EECD96"
            onChange={e => {
              console.log(e)
            }}
            StarIconComponent={StarIcon14}
            starStyle={styles.starStyle}
            style={[]}
            animationConfig={{
              scale: 1,
            }}
          />
        </View>
      </View>
      <View style={styles.divider} />
      <View style={[Gutters.smallTMargin, { gap: 2 }]}>
        {getFlavorAPIIsSuccess && (
          <FlatList
            data={item.flavors}
            renderItem={renderFlavors}
            keyExtractor={flavorsItem => flavorsItem}
            horizontal
            style={[Layout.fullWidth, Layout.row, Layout.flexWrap]}
            showsHorizontalScrollIndicator={false}
            ListEmptyComponent={() => (
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                尚無風味資訊
              </Text>
            )}
          />
        )}
        <Text
          style={[
            Fonts.size12,
            Fonts.weight400,
            { color: Colors.fontText.light.primary3 },
          ]}
        >
          {item.marketPrice
            ? `參考價格：NTD$ ${item.marketPrice.toLocaleString()}`
            : '尚無價格資訊'}
        </Text>
      </View>
      <TouchableOpacity
        style={styles.spiritCardCollect}
        onPress={() => handleAddWishlist(collectState, item.id)}
      >
        <Image
          style={[{ width: '80%', height: '80%' }]}
          source={collectState ? Images.collected : Images.collect}
        />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  spiritCardContainer: {
    maxWidth: 268,
    height: 235,
    backgroundColor: Colors.background.onSurface,
    marginRight: 10,
    padding: 16,
    borderRadius: 8,
  },
  spiritCardImageContainer: {
    width: 72,
    height: 72,
  },
  starStyle: {
    marginLeft: 0,
    marginRight: 0,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.background.top,
  },
  flavorDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  flavorsItem: {
    backgroundColor: '#464646',
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'center',
    borderRadius: 32,
    marginRight: 10,
    marginBottom: 8,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spiritCardCollect: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 20,
    height: 26,
  },
})

export default CustomSpiritCard
