import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TextInput,
  Switch,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { PagesEnum } from '@/Navigators/Application'
import { LoadingComponent } from '@/Components'
import { capitalizedSentence } from '@/Util/global'

import useGetFlavorData, { FlavorDataItem } from '../hooks/useGetFlavorData'
import BottomSubmitButton from '../components/bottomSubmitButton'
import useCheckIsAdvanceFlavor from '../hooks/useCheckIsAdvanceFlavor'

// TODO: 切換 toggle 時清除另一邊風味
const FlavorFilterModal = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const { flavorsData, filterMetaDataIsLoading, flavorDataIsLoading } =
    useGetFlavorData()
  const isAdvanceFlavor = useCheckIsAdvanceFlavor()
  const styles = getStyle()

  const [initialData, setInitialData] = useState<FlavorDataItem[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState<FlavorDataItem[]>([])

  const [isEnabled, setIsEnabled] = useState(isAdvanceFlavor)
  const toggleSwitch = () => {
    setIsEnabled(previousState => !previousState)
    // 切換時清空 flavor 資料以及 isAdvanceFlavor 狀態
    navigation.setParams({ flavor: undefined, isAdvanceFlavor: !isEnabled })
  }

  const handleGoBack = useCallback(() => {
    navigation.navigate(PagesEnum.MainFilterModal, {
      ...route.params,
    })
  }, [navigation, route.params])

  const handleReset = useCallback(() => {
    setSearchText('')
    setSearchResult(initialData)
    navigation.setParams({ flavor: undefined })
  }, [initialData, navigation])

  const resetButtonDisabled = useMemo(() => {
    return !route.params?.flavor
  }, [route.params])

  const handleSelect = (itemKey: string) => {
    const flavorParams = route?.params?.flavor
      ? typeof route.params.flavor === 'string'
        ? route.params.flavor.split(',')
        : route.params.flavor
      : []

    const newSelectedItems = flavorParams.includes(itemKey)
      ? flavorParams.filter((key: string) => key !== itemKey)
      : [...flavorParams, itemKey]

    const filter = {
      ...route.params,
      flavor: newSelectedItems,
    }

    navigation.setParams(filter)
  }

  useEffect(() => {
    if (flavorsData) {
      setInitialData(flavorsData)
    }
  }, [flavorsData])

  useEffect(() => {
    if (isAdvanceFlavor) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [isAdvanceFlavor])

  useEffect(() => {
    if (isEnabled) {
      const children = flavorsData.map(flavor => flavor.children).flat()
      setInitialData(children)
    } else {
      setInitialData(flavorsData)
    }
  }, [flavorsData, isEnabled])

  useEffect(() => {
    const filteredResults = searchText
      ? initialData.filter(
          item =>
            item.zhName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.enName.toLowerCase().includes(searchText.toLowerCase()),
        )
      : initialData

    setSearchResult(filteredResults)
  }, [initialData, searchText])

  if (filterMetaDataIsLoading || flavorDataIsLoading) {
    return (
      <View style={[styles.container]}>
        <LoadingComponent />
      </View>
    )
  }

  return (
    <View style={[Layout.fill]}>
      <ScrollView style={[styles.container]}>
        {/* header */}
        <View style={[styles.header]}>
          <TouchableOpacity onPress={handleGoBack}>
            <Image
              style={[styles.closeIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <Text
            style={[
              Fonts.weight600,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            風味
          </Text>
          <TouchableOpacity
            disabled={resetButtonDisabled}
            onPress={handleReset}
          >
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                {
                  color: resetButtonDisabled
                    ? Colors.fontText.disabled
                    : Colors.primary,
                },
              ]}
            >
              重設
            </Text>
          </TouchableOpacity>
        </View>
        {/* header */}
        <View style={[Gutters.regularHPadding]}>
          <View style={[styles.searchBar]}>
            <Image source={Images.search} style={[Layout.iconSize24]} />
            <TextInput
              style={[
                styles.searchInput,
                { color: Colors.fontText.light.primary2 },
              ]}
              value={searchText}
              onChangeText={setSearchText}
              placeholder="搜尋風味"
              placeholderTextColor={Colors.fontText.light.primary3}
            />
          </View>
          <View
            style={[
              Layout.row,
              Layout.justifyContentBetween,
              Layout.alignItemsCenter,
              Gutters.regularBMargin,
            ]}
          >
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              專業風味
            </Text>
            <Switch
              trackColor={{ false: '#767577', true: Colors.primary }}
              // web 需要用這個才能改顏色
              // @ts-ignore
              activeThumbColor={Colors.primaryLight}
              ios_backgroundColor="#3e3e3e"
              onValueChange={toggleSwitch}
              value={isEnabled}
            />
          </View>
          <View style={[styles.sectionContent]}>
            {searchResult.map(item => {
              const isSelected = route?.params?.flavor?.includes(item.enName)
              return (
                <TouchableOpacity
                  onPress={() => handleSelect(item.enName)}
                  style={[
                    styles.sectionContentItem,
                    isSelected && styles.sectionContentItemSelected,
                  ]}
                  key={item.enName}
                >
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size14,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {capitalizedSentence(item.zhName)}
                  </Text>
                  <Text
                    style={[
                      Fonts.weight300,
                      Fonts.size14,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {`(${item.count})`}
                  </Text>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
      </ScrollView>
      <BottomSubmitButton handleSubmit={handleGoBack} title="選取完成" />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.background.surface,
      height: Dimensions.get('window').height,
      paddingBottom: 150,
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 30,
      borderBottomWidth: 1,
      borderColor: Colors.background.top,
    },
    searchBar: {
      marginTop: 24,
      marginBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: Colors.fontText.light.primary3,
      borderRadius: 8,
    },
    searchInput: {
      flex: 1,
      marginLeft: 10,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    sectionContent: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
    },
    sectionContentItem: {
      boxSizing: 'border-box',
      width: '48.5%',
      borderWidth: 1,
      borderColor: Colors.background.top,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 8,
    },
    sectionContentItemSelected: {
      backgroundColor: Colors.primary,
      borderColor: 'none',
    },
  })

export default FlavorFilterModal
