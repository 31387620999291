import React, { useEffect, useMemo, useState } from 'react'
import {
  Image,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

import { FadeInView, LoadingComponent } from '@/Components'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { capitalizedSentence } from '@/Util/global'
import { PagesEnum } from '@/Navigators/Application'

import useGetFlavorData, { FlavorDataItem } from '../hooks/useGetFlavorData'

interface FlavorContainerProps {
  navigation: any
}

const TITLE = '風味分類'

const FlavorContainer = ({ navigation }: FlavorContainerProps) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()

  const { flavorsData, filterMetaDataIsLoading, flavorDataIsLoading } =
    useGetFlavorData()

  const [searchText, setSearchText] = useState('')
  const [listData, setListData] = useState<FlavorDataItem[]>(flavorsData)
  const [isEnabled, setIsEnabled] = useState(false)
  const toggleSwitch = () => setIsEnabled(previousState => !previousState)

  const styles = getStyle()

  useEffect(() => {
    if (searchText.length > 0) {
      // 專業風味
      if (isEnabled) {
        setListData(
          flavorsData
            .map(flavor => ({
              ...flavor,
              children: flavor.children.filter(
                child =>
                  child.zhName
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  child.enName.toLowerCase().includes(searchText.toLowerCase()),
              ),
            }))
            .filter(flavor => flavor.children.length > 0),
        )
      } else {
        setListData(
          flavorsData.filter(
            flavor =>
              flavor.enName.toLowerCase().includes(searchText.toLowerCase()) ||
              flavor.zhName.toLowerCase().includes(searchText.toLowerCase()),
          ),
        )
      }
    }
    if (searchText.length === 0) {
      setListData(flavorsData)
    }
  }, [flavorsData, searchText, isEnabled])

  const handleClickCard = (key: string) => {
    navigation.navigate(PagesEnum.FilterSearchListContainer, {
      flavor: key,
    })
  }

  const renderLoading = useMemo(() => {
    if (filterMetaDataIsLoading || flavorDataIsLoading) {
      return (
        <View style={[Layout.fill, Layout.rowCenter, Gutters.largeTPadding]}>
          <LoadingComponent backgroundColor="transparent" />
        </View>
      )
    }
  }, [
    Layout.fill,
    Layout.rowCenter,
    Gutters,
    filterMetaDataIsLoading,
    flavorDataIsLoading,
  ])

  return (
    <FadeInView duration={500} style={Layout.fill}>
      <View
        style={[Layout.fill, Gutters.regularHPadding, Gutters.smallTPadding]}
      >
        <View style={[styles.filterCardHeader]}>
          <Text
            style={[
              Fonts.weight700,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {`探索 - ${TITLE}`}
          </Text>
          <Switch
            trackColor={{ false: '#767577', true: Colors.primary }}
            // web 需要用這個才能改顏色
            // @ts-ignore
            activeThumbColor={Colors.primaryLight}
            ios_backgroundColor="#3e3e3e"
            onValueChange={toggleSwitch}
            value={isEnabled}
          />
        </View>
        {isEnabled && (
          <View style={[styles.searchBar]}>
            <Image source={Images.search} style={[Layout.iconSize24]} />
            <TextInput
              style={[
                styles.searchInput,
                { color: Colors.fontText.light.primary2 },
              ]}
              value={searchText}
              onChangeText={setSearchText}
              placeholder={`搜尋${TITLE}`}
              placeholderTextColor={Colors.fontText.light.primary3}
            />
            {searchText.length > 0 && (
              <TouchableOpacity onPress={() => setSearchText('')}>
                <Image source={Images.close} style={[Layout.iconSize24]} />
              </TouchableOpacity>
            )}
          </View>
        )}
        <View style={[Layout.fill]}>
          <View style={[styles.flavorCardContainer]}>
            {renderLoading}
            {/* 專業風味 */}
            {isEnabled ? (
              <View style={[Layout.fill]}>
                {listData.map(flavor => (
                  <View style={[Gutters.largeBMargin]}>
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        Gutters.tinyBMargin,
                        { color: Colors.fontText.light.primary2 },
                      ]}
                    >
                      {flavor.zhName}
                    </Text>
                    <View style={[styles.childrenFlavorContainer]}>
                      {flavor.children.map(child => (
                        <TouchableOpacity
                          style={[styles.flavorChildCard]}
                          onPress={() => handleClickCard(child.enName)}
                        >
                          <LinearGradient
                            style={styles.linearGradientContainer}
                            colors={child.linearGradient}
                            end={{ x: 90, y: 80 }}
                          >
                            <Text
                              style={[
                                Fonts.weight500,
                                Fonts.size14,
                                Gutters.miniBMargin,
                                { color: Colors.fontText.light.primary2 },
                              ]}
                            >
                              {child.zhName}
                            </Text>
                            <View
                              style={[
                                Layout.row,
                                Layout.alignItemsCenter,
                                styles.count,
                              ]}
                            >
                              <Image
                                source={Images.search_landing_bottle_icon}
                                style={[Layout.iconSize14, Gutters.miniRMargin]}
                              />
                              <Text
                                style={[
                                  Fonts.weight300,
                                  Fonts.size12,
                                  { color: Colors.fontText.light.primary3 },
                                ]}
                              >
                                {child.count}
                              </Text>
                            </View>
                            <Image
                              // @ts-ignore
                              source={Images[flavor.icon]}
                              resizeMode="contain"
                              style={[styles.flavorCardIcon]}
                            />
                          </LinearGradient>
                        </TouchableOpacity>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            ) : (
              // 一般風味（父層分類）
              listData.map(flavor => (
                <TouchableOpacity
                  style={[styles.flavorCard]}
                  onPress={() => handleClickCard(flavor.enName)}
                >
                  <LinearGradient
                    style={styles.linearGradientContainer}
                    colors={flavor.linearGradient}
                    end={{ x: 90, y: 80 }}
                  >
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        { color: Colors.fontText.light.primary2 },
                      ]}
                    >
                      {capitalizedSentence(flavor.enName)}
                    </Text>
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        Gutters.miniBMargin,
                        { color: Colors.fontText.light.primary2 },
                      ]}
                    >
                      {flavor.zhName}
                    </Text>
                    <View
                      style={[
                        Layout.row,
                        Layout.alignItemsCenter,
                        styles.count,
                      ]}
                    >
                      <Image
                        source={Images.search_landing_bottle_icon}
                        style={[Layout.iconSize14, Gutters.miniRMargin]}
                      />
                      <Text
                        style={[
                          Fonts.weight300,
                          Fonts.size12,
                          { color: Colors.fontText.light.primary2 },
                        ]}
                      >
                        {flavor.count}
                      </Text>
                    </View>
                    <Image
                      // @ts-ignore
                      source={Images[flavor.icon]}
                      resizeMode="contain"
                      style={[styles.flavorCardIcon]}
                    />
                  </LinearGradient>
                </TouchableOpacity>
              ))
            )}
          </View>
        </View>
      </View>
    </FadeInView>
  )
}

const getStyle = () =>
  StyleSheet.create({
    filterCardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    articleBanner: {
      width: '100%',
      height: 180,
    },
    searchBar: {
      marginBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: Colors.fontText.light.primary3,
      borderRadius: 8,
    },
    searchInput: {
      flex: 1,
      marginLeft: 10,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    flavorCardContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 12,
    },
    flavorCard: {
      width: '48%',
      height: 104,
      justifyContent: 'center',
      overflow: 'hidden',
    },
    flavorChildCard: {
      width: '48%',
      height: 84,
      justifyContent: 'center',
      overflow: 'hidden',
    },
    linearGradientContainer: {
      width: '100%',
      height: '100%',
      padding: 16,
      borderRadius: 10,
    },
    flavorCardIcon: {
      width: 91,
      height: 89,
      position: 'absolute',
      right: -15,
      bottom: -15,
      opacity: 0.05,
    },
    childrenFlavorContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 12,
    },
    count: { position: 'absolute', right: 25, bottom: 15 },
  })

export default FlavorContainer
