import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TextInput,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { PagesEnum } from '@/Navigators/Application'
import { LoadingComponent } from '@/Components'
import { capitalizedSentence } from '@/Util/global'

import useGetCountriesData, {
  CountryDataItem,
} from '../hooks/useGetCountriesData'
import BottomSubmitButton from '../components/bottomSubmitButton'

const CountryFilterModal = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const { countriesData, filterMetaDataIsLoading, countryDataIsLoading } =
    useGetCountriesData()

  const [initialData, setInitialData] = useState<CountryDataItem[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState<CountryDataItem[]>([])

  const handleGoBack = useCallback(() => {
    navigation.navigate(PagesEnum.MainFilterModal, {
      ...route.params,
    })
  }, [navigation, route.params])

  const handleReset = useCallback(() => {
    setSearchText('')
    setSearchResult(initialData)
    navigation.setParams({ country: undefined })
  }, [initialData, navigation])

  const resetButtonDisabled = useMemo(() => {
    return !route.params?.country
  }, [route.params])

  const handleSelect = (itemKey: string) => {
    const countryParams = route?.params?.country
      ? typeof route.params.country === 'string'
        ? route.params.country.split(',')
        : route.params.country
      : []

    const newSelectedItems = countryParams.includes(itemKey)
      ? countryParams.filter((key: string) => key !== itemKey)
      : [...countryParams, itemKey]

    const filter = {
      ...route.params,
      country: newSelectedItems,
    }

    navigation.setParams(filter)
  }

  useEffect(() => {
    if (countriesData) {
      setInitialData(countriesData)
    }
  }, [countriesData])

  useEffect(() => {
    const filteredResults = searchText
      ? initialData.filter(item =>
          item.zhName.toLowerCase().includes(searchText.toLowerCase()),
        )
      : initialData

    setSearchResult(filteredResults)
  }, [initialData, searchText])

  if (filterMetaDataIsLoading || countryDataIsLoading) {
    return (
      <View style={[styles.container]}>
        <LoadingComponent />
      </View>
    )
  }

  return (
    <View style={[Layout.fill]}>
      <ScrollView style={[styles.container]}>
        {/* header */}
        <View style={[styles.header]}>
          <TouchableOpacity onPress={handleGoBack}>
            <Image
              style={[styles.closeIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <Text
            style={[
              Fonts.weight600,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            國家
          </Text>
          <TouchableOpacity
            onPress={handleReset}
            disabled={resetButtonDisabled}
          >
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                {
                  color: resetButtonDisabled
                    ? Colors.fontText.disabled
                    : Colors.primary,
                },
              ]}
            >
              重設
            </Text>
          </TouchableOpacity>
        </View>
        {/* header */}
        <View style={[Gutters.regularHPadding]}>
          <View style={[styles.searchBar]}>
            <Image source={Images.search} style={[Layout.iconSize24]} />
            <TextInput
              style={[
                styles.searchInput,
                { color: Colors.fontText.light.primary2 },
              ]}
              value={searchText}
              onChangeText={setSearchText}
              placeholder="搜尋國家"
              placeholderTextColor={Colors.fontText.light.primary3}
            />
          </View>
          <View style={[styles.sectionContent]}>
            {searchResult.map(item => {
              const isSelected = route?.params?.country?.includes(item.enName)
              return (
                <TouchableOpacity
                  onPress={() => handleSelect(item.enName)}
                  style={[
                    styles.sectionContentItem,
                    isSelected && styles.sectionContentItemSelected,
                  ]}
                  key={item.enName}
                >
                  <View style={[Layout.row, Layout.alignItemsCenter]}>
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        Gutters.smallRMargin,
                      ]}
                    >
                      {item.emoji}
                    </Text>
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        {
                          color: isSelected
                            ? Colors.fontText.dark.primary2
                            : Colors.fontText.light.primary2,
                        },
                      ]}
                    >
                      {capitalizedSentence(item.zhName)}
                    </Text>
                  </View>
                  <Text
                    style={[
                      Fonts.weight300,
                      Fonts.size14,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {`(${item.count})`}
                  </Text>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
      </ScrollView>
      <BottomSubmitButton handleSubmit={handleGoBack} title="選取完成" />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.background.surface,
      height: Dimensions.get('window').height,
      paddingBottom: 150,
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 30,
      borderBottomWidth: 1,
      borderColor: Colors.background.top,
    },
    searchBar: {
      marginTop: 24,
      marginBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: Colors.fontText.light.primary3,
      borderRadius: 8,
    },
    searchInput: {
      flex: 1,
      marginLeft: 10,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    sectionContent: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
    },
    sectionContentItem: {
      width: '100%',
      height: 62,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: Colors.background.top,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 8,
    },
    sectionContentItemSelected: {
      backgroundColor: Colors.primary,
      borderColor: 'none',
    },
  })

export default CountryFilterModal
