import React, { useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Dimensions,
} from 'react-native'
import StarRating from 'react-native-star-rating-widget'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { FadeInView, Header, WarningSignComponent } from '@/Components'
import { capitalizedSentence, handleFormatRating } from '@/Util/global'
import { RootState } from '@/Store'
import { logApi } from '@/Services/logApi'
import { globalActions } from '@/Store/Global'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { MainPagesEnum } from '@/Navigators/Main'
import { PagesEnum } from '@/Navigators/Application'
import historyApi from '@/Services/modules/history'

enum ScanLogStatus {
  FirstTimeSuccess = 0,
  SuccessButReScan = 1,
  ReportScanResult = 4,
  SuggestionSuccess = 7,
}

const ScanResultContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.theme)

  const [hideHeader, setHideHeader] = useState<boolean>(false)
  const [imageURI] = useState(route?.params?.imageURI || '')
  const [scanData] = useState(route?.params?.scanData || [])
  const [sliderMatchData, setSliderMatchData] = useState(scanData?.data[0])
  const [sliderIndex, setSliderIndex] = useState(0)

  // 紀錄圖片搜尋 log
  const [lazyGetImageScanLogRequest] = logApi.useLazyGetImageScanLogQuery()

  // 更改紀錄的酒資料（點擊 suggestion spirit）
  const [lazyPatchRecognitionHistoriesRequest] =
    historyApi.usePatchRecognitionHistoriesMutation()

  const sliderNumber = scanData?.data?.length

  const styles = getStyle()

  const handleHideHeader = (event: any) => {
    const { y } = event.nativeEvent.contentOffset
    if (y > 50 && !hideHeader) {
      setHideHeader(true)
    }
    if (y < 50 && hideHeader) {
      setHideHeader(false)
    }
  }

  // 如果是 slider index === 0 即是最佳匹配，反之是建議匹配
  const handleCheckSpirit = (id: number, title: string) => {
    if (sliderIndex === 0) {
      lazyGetImageScanLogRequest({
        request_id: scanData.requestId,
        status: ScanLogStatus.FirstTimeSuccess as number,
      })
    } else {
      // 紀錄建議匹配的酒資料
      lazyGetImageScanLogRequest({
        request_id: scanData.requestId,
        status: ScanLogStatus.SuggestionSuccess as number,
      })
      // 需覆蓋辨識紀錄的資料
      lazyPatchRecognitionHistoriesRequest({
        historyId: scanData.historyId,
        spiritId: id.toString(),
      })
    }

    navigation.navigate(PagesEnum.ProductDetail, {
      id,
      lang: language,
      name: title,
    })
  }

  const handleReOpenCamera = () => {
    lazyGetImageScanLogRequest({
      request_id: scanData.requestId,
      status: ScanLogStatus.SuccessButReScan,
    })
    handleGoBack()
  }

  const handleGoBack = () => {
    navigation.navigate(MainPagesEnum.Camera)
  }

  const handleClickSlider = (type: 'prev' | 'next') => {
    if (type === 'prev' && sliderIndex > 0) {
      setSliderIndex(sliderIndex - 1)
    } else if (type === 'next' && sliderIndex < sliderNumber - 1) {
      setSliderIndex(sliderIndex + 1)
    }
  }

  const handleConfirmReport = () => {
    lazyGetImageScanLogRequest({
      request_id: scanData.requestId,
      status: ScanLogStatus.ReportScanResult,
    })
    dispatch(globalActions.closeBottomDialog())
    navigation.navigate(AuthPagesEnum.ReportSpiritContainer, {
      historyId: scanData.historyId,
    })
  }

  useEffect(() => {
    setSliderMatchData(scanData?.data?.[sliderIndex])
  }, [scanData, sliderIndex])

  // 確認回報辨識錯誤
  const openConfirmReportDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '確認回報？',
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.regularVPadding,
                Gutters.regularHPadding,
                {
                  borderBottomColor: Colors.gray,
                  borderBottomWidth: BorderWidth.width1,
                },
              ]}
            >
              <Text
                style={[Fonts.weight500, Fonts.size14, { color: Colors.white }]}
              >
                收到您的回報後，我們將檢查酒資料並盡快修正。此回報機制不會蒐集用戶個人資訊。
              </Text>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    backgroundColor: Colors.primary,
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={handleConfirmReport}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.black },
                  ]}
                >
                  確認回報
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title="辨識結果"
        leftIcon={
          <Image
            style={[Layout.iconSize24]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
      />
      <ScrollView
        style={[Layout.fill, Layout.fullHeight]}
        onScroll={handleHideHeader}
      >
        <ImageBackground
          style={[
            Layout.fullWidth,
            Layout.rowCenter,
            styles.ImageBackgroundContainer,
          ]}
          source={Images.scan_result_top_background}
        >
          {/* 使用者掃描的照片 */}
          <Image
            style={styles.scanImage}
            source={{ uri: imageURI }}
            resizeMode="cover"
          />
          <FadeInView duration={250} style={styles.imageContainer}>
            <TouchableOpacity
              style={[
                styles.sliderArrow,
                { opacity: sliderIndex === 0 ? 0.5 : 1 },
              ]}
              disabled={sliderIndex === 0}
              onPress={() => handleClickSlider('prev')}
            >
              <Image
                style={[Layout.iconSize40]}
                source={Images.arrowLeft}
                resizeMode="cover"
              />
            </TouchableOpacity>
            <View style={[Layout.colCenter]}>
              {sliderIndex === 0 && (
                <View style={[styles.bestMatchTag]}>
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size12,
                      Fonts.textCenter,
                      { color: Colors.fontText.dark.primary2 },
                    ]}
                  >
                    最相似
                  </Text>
                </View>
              )}
              <Image
                style={styles.prodImage}
                source={{ uri: sliderMatchData?.imageUrl }}
                resizeMode="cover"
              />
            </View>
            <TouchableOpacity
              style={[
                styles.sliderArrow,
                { opacity: sliderIndex === sliderNumber - 1 ? 0.5 : 1 },
              ]}
              disabled={sliderIndex === sliderNumber - 1}
              onPress={() => handleClickSlider('next')}
            >
              <Image
                style={[
                  Layout.iconSize40,
                  { transform: [{ rotate: '180deg' }] },
                ]}
                source={Images.arrowLeft}
                resizeMode="cover"
              />
            </TouchableOpacity>
          </FadeInView>
        </ImageBackground>
        <View style={[styles.sliderDotsContainer]}>
          {new Array(sliderNumber).fill(0).map((_: number, index: number) => {
            const backgroundColor =
              index === sliderIndex ? Colors.fontText.light.primary2 : '#545454'
            return <View style={[styles.sliderDot, { backgroundColor }]} />
          })}
        </View>
        <FadeInView duration={500} style={Gutters.largeBMargin}>
          <View
            style={[
              Gutters.smallTPadding,
              { backgroundColor: Colors.background.default },
            ]}
          >
            <View style={[Gutters.smallHPadding, Layout.alignItemsCenter]}>
              {/* 酒廠 */}
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size16,
                  Gutters.tinyBMargin,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                {capitalizedSentence(sliderMatchData?.brand)}
              </Text>
              {/* 酒名 */}
              <Text
                numberOfLines={2}
                ellipsizeMode="tail"
                style={[
                  Fonts.size20,
                  Fonts.weight500,
                  Fonts.textCenter,
                  Gutters.smallBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {capitalizedSentence(sliderMatchData?.title)}
              </Text>
              {/* 評分 */}
              <View style={[Layout.center, Gutters.smallBMargin]}>
                <View style={[Layout.row, Layout.alignItemsCenter]}>
                  {sliderMatchData?.ratings?.average ? (
                    <Text
                      style={[
                        Fonts.size16,
                        Fonts.weight400,
                        Gutters.tinyRMargin,
                        { color: Colors.fontText.light.primary2 },
                      ]}
                    >
                      {handleFormatRating(sliderMatchData?.ratings?.average)}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        Fonts.weight400,
                        Fonts.size16,
                        Gutters.tinyRMargin,
                        { color: Colors.fontText.light.primary2 },
                      ]}
                    >
                      暫無評分
                    </Text>
                  )}
                  <StarRating
                    rating={sliderMatchData?.ratings?.average || 0}
                    starSize={20}
                    color="#EECD96"
                    onChange={e => {
                      console.log(e)
                    }}
                    starStyle={styles.starStyle}
                    style={[Gutters.tinyRMargin]}
                    animationConfig={{
                      scale: 1,
                    }}
                  />
                </View>
                <View>
                  <Text
                    style={[
                      Fonts.weight400,
                      Fonts.size12,
                      Gutters.smallRMargin,
                      { color: Colors.fontText.light.primary3 },
                    ]}
                  >
                    {`( ${sliderMatchData?.ratings?.count} 個評分 )`}
                  </Text>
                </View>
              </View>
              {/* 查看品項 */}
              <TouchableOpacity
                style={[styles.checkBtn]}
                onPress={() =>
                  handleCheckSpirit(sliderMatchData?.id, sliderMatchData?.title)
                }
              >
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight500,
                    { color: Colors.primaryText },
                  ]}
                >
                  查看品項
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </FadeInView>
        {/* 下方 CTA */}
        <View
          style={[
            styles.bottomContainer,
            Layout.colCenter,
            Gutters.regularTPadding,
          ]}
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size16,
              Fonts.textCenter,
              Gutters.smallBMargin,
              { color: Colors.fontText.light.primary3 },
            ]}
          >
            找不到酒？
          </Text>
          <View
            style={[
              Layout.fullWidth,
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
              Gutters.regularHPadding,
              Gutters.tinyBPadding,
            ]}
          >
            <TouchableOpacity
              style={[styles.bottomActionButton]}
              onPress={handleReOpenCamera}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  Fonts.textCenter,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                重新拍照
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.bottomActionButton]}
              onPress={openConfirmReportDialog}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  Fonts.textCenter,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                回報辨識錯誤
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.warningSignContainer]}>
            <WarningSignComponent />
          </View>
        </View>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    imageContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 24,
      paddingTop: 32,
      gap: 24,
    },
    scanImage: {
      position: 'absolute',
      top: 16,
      left: 16,
      width: 64,
      height: 64,
      borderRadius: 32,
      borderWidth: 2,
      borderColor: Colors.fontText.light.primary3,
    },
    bestMatchTag: {
      paddingVertical: 4,
      paddingHorizontal: 16,
      borderRadius: 32,
      backgroundColor: Colors.primary,
      marginBottom: 8,
    },
    prodImage: {
      width: 120,
      height: 120,
      borderRadius: 8,
    },
    checkBtn: {
      width: '100%',
      height: 48,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: Colors.primary,
    },
    bottomContainer: {
      width: '100%',
      marginTop: 'auto',
    },
    warningSignContainer: {
      width: '100%',
      marginTop: 32,
    },
    ImageBackgroundContainer: {
      height: 218,
      backgroundColor: Colors.black,
      marginBottom: 8,
    },
    starStyle: {
      marginLeft: 0,
      marginRight: 0,
    },
    sliderArrow: {
      height: '100%',
    },
    sliderDotsContainer: {
      width: '100%',
      height: 16,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
    },
    sliderDot: {
      width: 5,
      height: 5,
      borderRadius: 2.5,
      backgroundColor: '#545454',
    },
    bottomActionButton: {
      width: '48%',
      height: 48,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.background.top,
    },
  })

export default ScanResultContainer
