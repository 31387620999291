import { useEffect, useState } from 'react'

import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'

import { convertMetaData } from '../utils/convertMetaData'

export interface CategoryDataItem {
  zhName: string
  enName: string
  count: number
}

const useGetCategoriesData = () => {
  const [initialData, setInitialData] = useState<CategoryDataItem[]>([])

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isLoading: filterMetaDataIsLoading,
    isSuccess: filterMetaDataIsSuccess,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得國家資訊
  const {
    data: categoryData,
    isLoading: categoriesDataIsLoading,
    isSuccess: categoriesDataIsSuccess,
  } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.CATEGORY,
  })

  // 整理初始化資料
  useEffect(() => {
    if (categoriesDataIsSuccess && filterMetaDataIsSuccess) {
      const categoryArr: CategoryDataItem[] = []
      const categoryMeta = convertMetaData(filterMetaData, 'category')

      for (const category of categoryMeta) {
        categoryArr.push({
          zhName: categoryData?.data?.roots?.[category.key] || '',
          enName: category.key,
          count: category.count,
        })
      }

      setInitialData(categoryArr)
    }
  }, [
    categoryData?.data?.roots,
    filterMetaData,
    filterMetaDataIsSuccess,
    categoriesDataIsSuccess,
    categoryData,
  ])

  return {
    categoriesData: initialData,
    filterMetaDataIsLoading,
    categoriesDataIsLoading,
  }
}

export default useGetCategoriesData
